const SecurityFunctions = {
  IsClientManagement: "IsClientManagement",
  IsExport: "IsExport",
  IsFileImportHistory: "IsFileImportHistory",
  IsLoanEdit: "IsLoanEdit",
  IsLoanView: "IsLoanView",
  IsMaintenanceDealer: "IsMaintenanceDealer",
  IsMaintenanceProvider: "IsMaintenanceProvider",
  IsProviderConfiguration: "IsProviderConfiguration",
  IsReportsAdmin: "IsReportsAdmin",
  IsReportsAllied: "IsReportsAllied",
  IsReportsClient: "IsReportsClient",
  IsRoleManagement: "IsRoleManagement",
  IsSearch: "IsSearch",
  IsSearchByClient: "IsSearchByClient",
  IsSystemManagement: "IsSystemManagement",
  IsUserManagement: "IsUserManagement",
  IsWorkflowOverride: "IsWorkflowOverride",
  IsWorkflowTasks: "IsWorkflowTasks",
  IsAllied: "IsAllied",
  IsWorkflowAdmin: "IsWorkflowAdmin",
  IsSecureFieldEdit: "IsSecureFieldEdit",
  IsRefundFieldEdit: "IsRefundFieldEdit",
  IsLockboxCheck: "IsLockboxCheck",
  IsUploadDocuments: "IsUploadDocuments",
  IsRemittanceManagement: "IsRemittanceManagement",
  IsDownloadViewSuppportingDocs: "IsDownloadViewSuppportingDocs",
  IsUtilityManagement: "IsUtilityManagement",
  IsDeleteNote: "IsDeleteNote",
  IsEditNote: "IsEditNote",
  IsStateRulesAdmin: "IsStateRulesAdmin",
};

export default SecurityFunctions;
