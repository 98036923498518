import React from "react";
import PropTypes from "prop-types";

const SvgDoubleRight = props => {
   const { color, style, ...rest } = props;
   return (
      <svg width={12} height={6} {...rest}>
         <defs>
            <path id="doubleRight_svg__a" d="M10.7617627,5.35199704 L10.8320503,5.4452998 L12.8320503,8.4452998 C13.0311018,8.74387706 13.0532186,9.12281157 12.8984008,9.439246 L12.8320503,9.5547002 L10.8320503,12.5547002 C10.5256978,13.0142289 9.90482849,13.1384028 9.4452998,12.8320503 C9.01859459,12.5475802 8.88104736,11.9919194 9.10885776,11.5460663 L9.16794971,11.4452998 L10.797,9 L9.16794971,6.5547002 C8.88347957,6.12799499 8.97022855,5.56217462 9.35199704,5.23823728 L9.4452998,5.16794971 C9.87200501,4.88347957 10.4378254,4.97022855 10.7617627,5.35199704 Z M6.76176272,5.35199704 L6.83205029,5.4452998 L8.83205029,8.4452998 C9.0311018,8.74387706 9.05321863,9.12281157 8.8984008,9.439246 L8.83205029,9.5547002 L6.83205029,12.5547002 C6.52569784,13.0142289 5.90482849,13.1384028 5.4452998,12.8320503 C5.01859459,12.5475802 4.88104736,11.9919194 5.10885776,11.5460663 L5.16794971,11.4452998 L6.798,9 L5.16794971,6.5547002 C4.88347957,6.12799499 4.97022855,5.56217462 5.35199704,5.23823728 L5.4452998,5.16794971 C5.87200501,4.88347957 6.43782538,4.97022855 6.76176272,5.35199704 Z" />
         </defs>
         <g transform="translate(-3 -6)" fill="none" fillRule="evenodd">
            <mask id="doubleRight_svg__b" fill="#fff">
               <use xlinkHref="#doubleRight_svg__a" />
            </mask>
            <g mask="url(#doubleRight_svg__b)" fill={color}>
               <path d="M0 0h18v18H0z" />
            </g>
         </g>
      </svg>
   );
};

SvgDoubleRight.propTypes = {
   color: PropTypes.string.isRequired,
   style: PropTypes.shape()
};

SvgDoubleRight.defaultProps = {
   style: null
};

export default SvgDoubleRight;
