import { Suspense, lazy } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { App as AntdApp, ConfigProvider, Spin } from "antd";

import {
  AuthProvider,
  AuthReducer,
  AuthorizeRoute,
  InitialAuthState,
  SecurityFunctions,
  Unauthorized,
  useAuthValue,
} from "./auth";
import {
  ADMINISTRATION_ROUTE,
  DEALER_LIST_ROUTE,
  LOAN_ROUTE,
  LOAN_SEARCH_ROUTE,
  LOGIN_ROUTE,
  PROVIDER_LIST_ROUTE,
  REPORT_LANDING_ROUTE,
  SYSTEM_ACTIVITY_ROUTE,
  TASK_LIST_ROUTE,
  WELCOME_ROUTE,
} from "./constants";

const LoanViewLazyLoad = lazy(
  () => import("./components/LoanView/LoanViewLazyLoad")
);
const SearchResult = lazy(() => import("./components/LoanSearch/SearchResult"));
const Welcome = lazy(() => import("./components/Welcome/Welcome"));
const TaskList = lazy(() => import("./components/TaskList"));
const AdministrationLanding = lazy(
  () => import("./components/Administration/AdministrationLanding")
);
const Header = lazy(() => import("./components/Header"));
const Navigation = lazy(() => import("./components/Navigation"));
const ProviderManagement = lazy(
  () => import("./components/ProviderManagement/ProviderManagement")
);
const DealerManagement = lazy(
  () => import("./components/DealerManagement/DealerManagement")
);
const ReportsLanding = lazy(
  () => import("./components/ReportManagement/ReportsLanding")
);
const SystemActivity = lazy(
  () => import("./components/SystemActivity/SystemActivity")
);
const LoginPage = lazy(() => import("./components/LoginPage"));

const queryClient = new QueryClient();

const configProviderProps = {
  hashed: false,
};

const App = () => {
  return (
    <AuthProvider reducer={AuthReducer} initialState={InitialAuthState}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider theme={configProviderProps}>
          <AntdApp notification={{ placement: "bottomRight" }}>
            <Suspense
              fallback={
                <Spin size="large" fullscreen className="fullscreen-spinner" />
              }
            >
              <Router>
                <Switch>
                  <Route exact path={LOGIN_ROUTE} component={LoginPage} />
                  <Route path="*" component={Layout} />
                </Switch>
              </Router>
            </Suspense>
          </AntdApp>
        </ConfigProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
};

const Layout = () => {
  const [{ isAuthenticated }] = useAuthValue();

  if (!isAuthenticated) {
    // If explicitly not authenticated, redirect to login page
    if (isAuthenticated === false) {
      return <Redirect to={LOGIN_ROUTE} />;
    }

    // If auth state is falsey but not explicitly false, auth state is still loading
    return <Spin size="large" fullscreen className="fullscreen-spinner" />;
  }

  return (
    <div className="application-body">
      <Suspense fallback={<Spin delay={200} />}>
        <Navigation />
        <Suspense fallback={<Spin size="small" delay={200} />}>
          <Header />
        </Suspense>
        <div
          style={{
            marginTop: "70px",
            marginLeft: "232px",
            backgroundColor: "#f1f2f4",
            width: "100%",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              minHeight: "80vh",
              width: "100%",
            }}
          >
            <Suspense fallback={<Spin delay={200} />}>
              <Switch>
                <Route exact path={WELCOME_ROUTE} component={Welcome} />
                <AuthorizeRoute
                  exact
                  path={LOAN_ROUTE}
                  component={LoanViewLazyLoad}
                  required={SecurityFunctions.IsLoanView}
                  unauthorized={Unauthorized}
                />
                <AuthorizeRoute
                  exact
                  path={LOAN_SEARCH_ROUTE}
                  component={SearchResult}
                  required={SecurityFunctions.IsSearch}
                  unauthorized={Unauthorized}
                />
                <AuthorizeRoute
                  path={REPORT_LANDING_ROUTE}
                  component={ReportsLanding}
                  requiresOneOf={[
                    SecurityFunctions.IsReportsAdmin,
                    SecurityFunctions.IsReportsAllied,
                    SecurityFunctions.IsReportsClient,
                  ]}
                  unauthorized={Unauthorized}
                />
                <AuthorizeRoute
                  exact
                  path={SYSTEM_ACTIVITY_ROUTE}
                  component={SystemActivity}
                  required={SecurityFunctions.IsSystemManagement}
                  unauthorized={Unauthorized}
                />
                <Route path={DEALER_LIST_ROUTE} component={DealerManagement} />
                <Route
                  path={PROVIDER_LIST_ROUTE}
                  component={ProviderManagement}
                />
                <AuthorizeRoute
                  path={ADMINISTRATION_ROUTE}
                  component={AdministrationLanding}
                  requiresOneOf={[
                    SecurityFunctions.IsUserManagement,
                    SecurityFunctions.IsClientManagement,
                    SecurityFunctions.IsWorkflowTasks,
                    SecurityFunctions.IsSystemManagement,
                    SecurityFunctions.IsRoleManagement,
                    SecurityFunctions.IsFileImportHistory,
                    SecurityFunctions.IsExport,
                  ]}
                  unauthorized={Unauthorized}
                />
                <AuthorizeRoute
                  path={TASK_LIST_ROUTE}
                  component={TaskList}
                  required={SecurityFunctions.IsWorkflowTasks}
                  unauthorized={Unauthorized}
                />
                <Route path="*">
                  <Redirect to={WELCOME_ROUTE} />
                </Route>
              </Switch>
            </Suspense>
          </div>
        </div>
      </Suspense>
    </div>
  );
};

export default App;
